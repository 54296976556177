import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AddButton, FileInput, SelectInput, ShowFile, TextAreaInput, TextInput } from "../Common/FormComponents";
import { useForm } from "../../hooks";
import { toast } from "react-toastify";
import { addVendor } from "../../utils/venderUtils/venderUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";

const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

const VendorForm = ({
  setOpen,
  editData = null,
  setEditData,
  setDatasFn,
  setResponse,
  setOtpSended,
}) => {
  const [err, setErr] = useState({ global: ""});
  const initialState = {
    vendor_company_name: editData?.vendor_company_name || "",
    email_id: editData?.email_id || "",
    phone_number: editData?.phone_number || "",
    aadhar_card: editData?.aadhar_card || "",
    Pancard: editData?.Pancard || "",
    office_address: editData?.office_address || "",
    service_type: editData?.service_type || "",
  };
  const bankAccInitialState = {
    bank_name:  "",
    account_no: "",
    ifsc_code: "",
    branch_name: "",
    account_type: "Select Account Type",
  };
  const [bankAccForm, handleBankAccForm, clearBankAccForm] =
    useForm(bankAccInitialState);
  const [bank_account_details, setBankAccDetails] = useState(
    editData?.bank_account_details || []
  );
  const handleBankAccDetails = (action, idx = 0) => {
    if (action === "add") {
      if (bank_account_details.length >= 2) {
        return setErr({ global: "Maximum 2 Accounts" });
      }
      if (!bankAccForm.bank_name.trim().length) {
        return setErr({ global: "Please Enter Bank Name" });
      }
      if (bankAccForm.account_type === "Select Account Type") {
        return setErr({ global: "Please Select An Account Type" });
      }
      if (!bankAccForm.account_no.trim().length) {
        return setErr({ global: "Please Enter Account Number" });
      }
      if (!bankAccForm.ifsc_code.trim().length) {
        return setErr({ global: "Please Enter IFSC Code" });
      }
      if (!bankAccForm.branch_name.trim().length) {
        return setErr({ global: "Please Enter Branch Name" });
      }
      setBankAccDetails([
        ...bank_account_details,
        {
          bank_name: bankAccForm?.bank_name,
          account_no: bankAccForm?.account_no,
          ifsc_code: bankAccForm?.ifsc_code,
          branch_name: bankAccForm?.branch_name,
          account_type: bankAccForm?.account_type,
        },
      ]);
      clearBankAccForm();
    } else if (action === "remove") {
      setBankAccDetails(
        bank_account_details.filter((_, index) => index !== idx)
      );
    }
  };
  const [file, setFile] = useState(editData?.Upload_company_document || null);
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.vendor_company_name.trim().length) {
      return setErr({ global: "Invalid Name" });
    }
    if (!emailPattern.test(formData.email_id)) {
      return setErr({ global: "Invalid Email" });
    }
    if (!phoneNumberPattern.test(formData.phone_number)) {
      return setErr({ global: "Invalid Phone Number" });
    }
    if (!formData.aadhar_card.trim().length) {
      return setErr({ global: "Invalid Aadhaar Card Number" });
    }
    if (!formData.Pancard.trim().length) {
      return setErr({ global: "Invalid Pan Card Number" });
    }
    if (!formData.service_type.trim().length) {
      return setErr({ global: "Invalid Service Type" });
    }
    if (!formData.office_address.trim().length) {
      return setErr({ global: "Invalid Office Address" });
    }
    if (!file) {
      return setErr({ global: "Please Select File" });
    }
    if (!bank_account_details.length) {
      return setErr({ global: "Add At Least One Bank Account" });
    }
    setErr({ global: "" });
    setLoading(true);
    addVendor(formData, bank_account_details, file, editData?._id)
      .then((res) => {
        if (!editData) {
          setResponse(res.data.data);
          toast(`OTP sent successfully!`, {
            type: "success",
            autoClose: 3000,
          });
          setOtpSended(true);
        } else {
          setDatasFn();
          toast(`Vender Edited`, {
            type: "success",
            autoClose: 3000,
          });
          setEditData(null);
          setOpen(false);
        }
        clearForm();
        setFile(null);
      })
      .catch((err) => {
        console.log(err);
        if (!editData) {
          toast(`Failed to send OTP. Please try again.`, {
            type: "error",
            autoClose: 3000,
          });
        } else {
          toast(`Vender Edit Failed!`, {
            type: "success",
            autoClose: 3000,
          });
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit Vender" : "Add new Vender"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Name/Company Name"}
            formName={"vendor_company_name"}
            data={formData}
            handleInput={handleInput}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Email"}
            formName={"email_id"}
            data={formData}
            handleInput={handleInput}
            setError={setErr}
          />
          <TextInput
            name={"Phone"}
            formName={"phone_number"}
            data={formData}
            handleInput={handleInput}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Aadhaar Card Number"}
            formName={"aadhar_card"}
            data={formData}
            handleInput={handleInput}
            setError={setErr}
          />
          <TextInput
            name={"Pan Card Number"}
            formName={"Pancard"}
            data={formData}
            handleInput={handleInput}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Service Type"}
            formName={"service_type"}
            data={formData}
            handleInput={handleInput}
            setError={setErr}
          />
          <TextAreaInput
            name={"Office Address"}
            formName={"office_address"}
            data={formData}
            handleInput={handleInput}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowFile file={file} setFile={setFile} />
          <FileInput fileName={"Document"} setFile={setFile} />
        </div>
        {bank_account_details?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {bank_account_details?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <span>{item?.bank_name}</span>
                <button
                  onClick={() => {
                    handleBankAccDetails("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Bank Name"}
            formName={"bank_name"}
            data={bankAccForm}
            handleInput={handleBankAccForm}
            setError={setErr}
          />
          <SelectInput
            name={"Account Type"}
            formName={"account_type"}
            data={bankAccForm}
            items={["Select Account Type", "Savings", "Current", "Others"]}
            handleInput={handleBankAccForm}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Account No"}
            formName={"account_no"}
            data={bankAccForm}
            handleInput={handleBankAccForm}
            setError={setErr}
          />
          <TextInput
            name={"IFSC Code"}
            formName={"ifsc_code"}
            data={bankAccForm}
            handleInput={handleBankAccForm}
            setError={setErr}
          />
        </div>
        <div className="flex sm:w-1/2 h-fit">
          <TextInput
            name={"Branch Name"}
            formName={"branch_name"}
            data={bankAccForm}
            handleInput={handleBankAccForm}
            setError={setErr}
          />
        </div>
        {bank_account_details.length < 2 && (
          <AddButton handleAdd={handleBankAccDetails} />
        )}
        {bank_account_details.length >= 2 && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-start items-center font-semibold">
            Maximum 2 accounts!
          </div>
        )}
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {loading ? "Please wait..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorForm;
