import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { Login, Vendors } from './pages';
import { AuthProtected, UserProtected } from "./components/ProtectedRoutes/UserProtected";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { saveUser } from "./redux/userSlice";


function App() {
  const dispatch = useDispatch();
  const saveUserFromLocalStorage = useCallback(() => {
    const vendor = JSON.parse(localStorage.getItem("vendor"));
    if (vendor) {
      dispatch(saveUser(vendor));
    }
  }, [dispatch]);
  useEffect(() => {
    saveUserFromLocalStorage();
  }, [saveUserFromLocalStorage]);
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Vendors/>} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
