import React, {  useCallback, useEffect, useState } from 'react'
import { SearchInput } from '../Common';
import { FaUserPlus } from "react-icons/fa6";
import VendorsTable from "./VendorsTable";
import VenderModal from "./VenderModal";
import { getVendors } from '../../utils/venderUtils/venderUtils';

const VendorsMainSection = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [datas, setDatas] = useState([]);
    const setDatasFn = useCallback(() => {
      getVendors()
        .then((res) => setDatas(res.data.data))
        .catch((err) => console.log(err));
  },[]);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdDatas, setFilteredDatas] = useState([]);
  useEffect(() => {
    const filteredData = datas?.filter((data) => {
      const nameMatch = data?.vendor_company_name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const phoneMatch = data?.phone_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const emailMatch = data?.email_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || phoneMatch || emailMatch;
    });
    setFilteredDatas(filteredData);
  }, [datas, searchQuery]);
    return (
      <>
        {open && (
          <VenderModal
            setOpen={setOpen}
            editData={editData}
            setEditData={setEditData}
            setDatasFn={setDatasFn}
          />
        )}
        <div className=" flex w-full items-center gap-5 flex-col">
          <div className="flex w-fit h-fit text-xl font-semibold">
            Vendors
          </div>
          <div className="flex w-full h-full justify-center items-center">
            <div className="flex w-full md:w-1/2">
              <SearchInput
                placeholder={"Search by Name, Phone, Email"}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full p-3 flex-col">
          <div className="flex w-full justify-between items-center h-fit">
            <span className="font-semibold text-base">Informations</span>
            <button
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className={`flex text-white text-sm rounded-md gap-2 p-2 items-center`}
              onClick={() => setOpen(true)}
            >
              <span>
                <FaUserPlus />
              </span>
              <span>Vendor</span>
            </button>
          </div>
          <VendorsTable
            setOpen={setOpen}
            setEditData={setEditData}
            datas={filterdDatas}
            setDatasFn={setDatasFn}
          />
        </div>
      </>
    );
}

export default VendorsMainSection