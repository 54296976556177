export const validateLoginForm = (initialState, setErr) => {
    const idPattern = /^MD-Accountant-\d*$/;
    const passwordPattern = /^.{8,}$/

  if (!idPattern.test(initialState.vendor_id.trim())) {
        setErr({ global: "Invalid User Id" });
        return;
    }
    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
}